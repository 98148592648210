<template>
  <bm-marker :key="`${item.id}_${item.type}`" :dragging="enableMoveDevice" :icon="{url: getImgType(item), size:getSizeType(item)}"
    :position="{lng: item.lng, lat: item.lat}" :name="item.name" @click="showMakerInfo" @dragend="makerPosChanged">
    <bm-label :content="item.name" :offset="getOffsetType(item)" :labelStyle="labelStyle" />
  </bm-marker>
</template>

<script>
// import { BmOverlay } from 'vue-baidu-map'
import {
  BmMarker,
  BmLabel,
} from '@/components/vue-baidu-map/components'
export default {
  components: {
    BmMarker,
    BmLabel,
  },
  // inject: ['injectObj'],
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default() {
        return {
          lat: 0,
          lng: 0,
          id: 0,
          type: 0,
          alarm: false,
          online: false,
          running: false,
          code: '',
          name: '',
          path: {},
          status: 1,
          single: true,
          sid: 0,
        };
      },
    },
    enableMoveDevice: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      labelStyle:{
        width: '150px', textAlign:'center', backgroundColor: 'transparent', color: '#2d8cf0', border: 0, fontSize : '12px'
      },
      offsetWidth: 80,
      keyIndex: 1,
    }
  },
  watch: {
    // enableMoveDevice(){
    //   console.log('enable dev move', this.enableMoveDevice)
    // },
    // item(){
    //   console.log('map group total data changed', this.item)
    // }
    // position: {
    //   handler() {
    //     this.$refs[`customOverlay${this.sample.name}`].reload()  // 当位置发生变化时，重新渲染，内部会调用draw
    //   },
    //   deep: true
    // }
  },
  computed: {
    // enableMoveDevice() {
    //   return this.injectObj.enableMoveDevice
    // }
  },
  mounted() {
    this.sample = this.item;
  },
  methods: {
    makerPosChanged: function(params){
      this.$emit('posChanged', {id:this.item.id,lat:params.point.lat, lng: params.point.lng});
    },
    showMakerInfo: function () {
      // this.editItem = params;
      // this.showMakerInfoModal = true;
      this.$emit("click", this.item);
    },
    getOffsetType() {
      return { width: -45, height: 90 };
    },
    getSizeType() {
      return  { width: 60, height: 87 };
    },
    getImgType: function (dev) {
      if(dev.status != 1) return this.img.mapPowerRepair;//维修
      // else if(!dev.online) return this.img.mapPowerOffline;
      else if(!dev.online) return this.img.gis.box.offlineBig;//离线
      else {
        if(dev.running){
          if(dev.alarm){
            return this.img.gis.box.lightingPoliceBig//亮灯报警
          }else{
            return this.img.gis.box.lightingBig;//亮灯
          }
        }else{
          if(dev.alarm){
            return this.img.gis.box.outPoliceBig//灭灯报警
          }else{
            return this.img.gis.box.outBig;//灭灯
          }
        }
      }
    },
    groupInfo() {
      this.$emit('overLayBut', this.sample);
    },
    draw({ el, BMap, map }) {
      // const { lng, lat } = this.position
      const pixel = map.pointToOverlayPixel(new BMap.Point(this.item.lng, this.item.lat))
      console.log('over lay draw', this.item, el, map, BMap, pixel)
      // this.offsetWidth = this.$refs[`overlayBox${this.sample.name}`].offsetWidth;
      if (this.offsetWidth == 0) { this.offsetWidth = 80 }
      el.style.left = '100px';// pixel.x - this.offsetWidth / 2 + 'px'
      el.style.top = '100px';//pixel.y - 34 + 'px';
      this.keyIndex = Math.random() * 10000000000000;
    }
  }
}
</script>

<style>
.overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
}
.overlay-box {
  display: flex;
  /* width: auto; */
  height: 34px;
  background: #3880fc;
  border-radius: 17px;
  line-height: 34px;
  padding-right: 10px;
  /* position: relative; */
  /* border: solid 1px red; */
}
.overlay-img {
  width: 28px;
  height: 28px;
  flex: none;
  background: #ffffff;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  margin-left: 3px;
  margin-top: 3px;
}
.overlay-img img {
  width: 14px;
  height: 18px;
}
.overlay-line {
  flex: none;
  width: 1px;
  height: 28px;
  background: #ffffff;
  display: inline-block;
}
.overlay-name {
  white-space: nowrap;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  /* margin-left: 9px; */
  vertical-align: top;
  border-right: 1px solid #fff;
  padding: 0 15px;
}
.overlay-num {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  vertical-align: top;
  margin: 0 9px;
}
.overlay-triangle {
  width: 0;
  height: 0;
  border-top: 8px solid #3880fc;
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;
  position: absolute;
  top: 34px;
  left: 60px;
  /* border: solid 1px red; */
}
</style>