<template>
  <bm-control :offset="item.offset" anchor="BMAP_ANCHOR_TOP_RIGHT">
    <div class="map-data-stat">
      <div class="map-data-type">
        <div class="map-data-top"><span><i class="map-data-drop"></i>{{showLang('com.device.box')}}</span><i>{{item.power.total}}</i></div>
        <div class="map-data-son">
          <img :src="img.mapPowerOnline" alt="">
          <span class="map-data-name">{{showLang('com.state.onLine')}}</span>
          <span class="map-data-num">{{item.power.online}}</span>
        </div>
        <div class="map-data-son">
          <img :src="img.mapPowerAlarm" alt="">
          <span class="map-data-name">{{showLang('com.state.energize')}}</span>
          <span class="map-data-num">{{item.power.running}}</span>
        </div>
        <div class="map-data-son">
          <img :src="img.mapPowerError" alt="">
          <span class="map-data-name">{{showLang('com.right.nav.alarm')}}</span>
          <span class="map-data-num">{{item.power.alarm}}</span>
        </div>
        <div class="map-data-son">
          <img :src="img.mapPowerOffline" alt="">
          <span class="map-data-name">{{showLang('com.state.offline')}}</span>
          <span class="map-data-num">{{item.power.offline}}</span>
        </div>
        <div class="map-data-son">
          <img :src="img.mapPowerRepair" alt="">
          <span class="map-data-name">{{showLang('com.state.service')}}</span>
          <span class="map-data-num">{{item.power.repair}}</span>
        </div>
        <div class="map-data-son">
          <img :src="img.powerbox.status.stop" alt="">
          <span class="map-data-name">{{showLang('com.state.deactivate')}}</span>
          <span class="map-data-num">{{item.power.stop}}</span>
        </div>
      </div>
      <div class="map-data-type">
        <div class="map-data-top"><span><i class="map-data-drop"></i>{{showLang('com.device.lamp')}}</span><i>{{item.light.total}}</i></div>
        <div class="map-data-son">
          <img :src="img.gis.pole.out2" alt="">
          <span class="map-data-name">{{showLang('com.state.onLine')}}</span>
          <span class="map-data-num">{{item.light.online}}</span>
        </div>
        <div class="map-data-son">
          <img :src="img.gis.pole.lighting2" alt="">
          <span class="map-data-name">{{showLang('com.but.open.lamp')}}</span>
          <span class="map-data-num">{{item.light.running}}</span>
        </div>
        <div class="map-data-son">
          <img :src="img.gis.pole.outP3" alt="">
          <span class="map-data-name">{{showLang('com.state.police')}}</span>
          <span class="map-data-num">{{item.light.alarm}}</span>
        </div>
        <div class="map-data-son">
          <img :src="img.gis.pole.outOff2" alt="">
          <span class="map-data-name">{{showLang('com.state.offline')}}</span>
          <span class="map-data-num">{{item.light.offline}}</span>
        </div>
      </div>
      <div class="map-data-type">
        <div class="map-data-top"><span><i class="map-data-drop"></i>{{showLang('com.device.lightControl')}}</span><i>{{item.lux.total}}</i>
        </div>
      </div>
      <div class="map-data-type">
        <div class="map-data-top"><span><i class="map-data-drop"></i>{{showLang('com.device.cable')}}</span><i>{{item.line.total}}</i></div>
      </div>
    </div>
  </bm-control>
</template>
<script>
import { mapGetters } from 'vuex'
import {
  BmControl,
} from '@/components/vue-baidu-map/components'
export default {
  components: {
    BmControl
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default() {
        return {
          type: 'BMAP_NORMAL_MAP',
          offset: {
            width: 20,
            height: 30
          }
        };
      },
    },
  },
  data() {
    return {
      deviceType: 'all',
      deviceArray: [
        { name: "全部", type: "all", lang: 'com.state.all' },
        { name: "配电柜", type: "powerbox", lang: 'com.device.box' },
        { name: "单灯", type: "light", lang: 'com.device.lamp' },
        { name: "光控", type: "lux", lang: 'com.device.lightControl' },
        { name: "电缆", type: "cable", lang: 'com.device.cable' },
      ],
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes', 'showLang']),
  },
  watch: {
    // position: {
    //   handler() {
    //     this.$refs[`customOverlay${this.sample.name}`].reload()  // 当位置发生变化时，重新渲染，内部会调用draw
    //   },
    //   deep: true
    // }
  },
  mounted() {
  },
  methods: {
    switchDeviceType: function (type) {
      this.$emit('changed', {type});
    },
  }
}
</script>

<style>
.map-data-stat {
  width: 191px;
  height: 595px;
  background: #ffffff;
  box-shadow: 0px 4px 30px 0px rgba(56, 128, 252, 0.2);
  border-radius: 6px;
  padding: 20px;
}
.map-data-stat i {
  font-style: inherit;
}
.map-data-top {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #021629;
  display: flex;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
}
.map-data-son {
  /* border: solid 1px red; */
  height: 35px;
  font: index 20px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #324252;
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
}
.map-data-son img {
  width: 22px;
  height: 28px;
  margin-left: 5px;
}
.map-data-name {
  position: absolute;
  left: 60px;
}
.map-data-drop {
  width: 6px;
  height: 6px;
  background: #515e6b;
  border-radius: 3px;
  display: inline-block;
  margin-right: 8px;
  vertical-align: middle;
}
</style>