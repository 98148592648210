<template>
  <bm-marker :dragging="enableMoveDevice" :icon="{ url: getImgType(item), size: getSizeType(item) }" :position="{ lng: item.lng, lat: item.lat }" :name="item.name" @click="showMakerInfo" @dragend="makerPosChanged">
    <bm-label :content="item.name" :offset="getOffsetType(item)" :labelStyle="labelStyle1" />
    <bm-label :content="item.pcnt + ''" v-if="item.pcn > 3" :offset="getOffsetType2" :labelStyle="labelStyle2" />
    <bm-label :content="''" v-if="isSingleShow" :offset="{ width: -6, height: -6 }" :labelStyle="{ width: '68px', height: '90px', textAlign: 'center', border: '1px solid #f00',color:'red' , zIndex: '20', backgroundColor: 'transparent' }"></bm-label>
  </bm-marker>
</template>

<script>
// import { BmOverlay } from 'vue-baidu-map'
import { BmMarker, BmLabel } from '@/components/vue-baidu-map/components'
export default {
  components: {
    BmMarker,
    BmLabel
  },
  // inject: ['injectObj'],
  props: {
    value: {
      type: Boolean,
      default: false
    },
    isSingleShow: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default() {
        return {
          lat: 0,
          lng: 0,
          id: 0,
          type: 0,
          alarm: false,
          online: false,
          running: false,
          code: '',
          name: '',
          path: {},
          status: 1,
          single: true,
          sid: 0
        }
      }
    },
    enableMoveDevice: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      labelStyle1: {
        width: '150px',
        textAlign: 'center',
        backgroundColor: 'transparent',
        color: '#2d8cf0',
        border: 0,
        fontSize: '12px'
      },
      labelStyle2: {
        width: '24px',
        height: '24px',
        lineHeight: '24px',
        textAlign: 'center',
        border: '#3880FC',
        backgroundColor: '#3880FC',
        color: '#FFFFFF',
        borderRadius: '50%',
        fontSize: '14px'
      },
      offsetWidth: 80,
      keyIndex: 1
    }
  },
  computed: {},
  watch: {
    // item(){
    //   console.log('map group total data changed', this.item)
    // }
    // position: {
    //   handler() {
    //     this.$refs[`customOverlay${this.sample.name}`].reload()  // 当位置发生变化时，重新渲染，内部会调用draw
    //   },
    //   deep: true
    // }
  },
  mounted() {
    this.sample = this.item
  },
  methods: {
    makerPosChanged: function (params) {
      this.$emit('posChanged', { id: this.item.id, lat: params.point.lat, lng: params.point.lng })
    },
    showMakerInfo: function () {
      this.$emit('click', this.item)
    },
    getOffsetType() {
      return { width: -48, height: 60 }
    },
    getOffsetType2() {
      return { width: 15, height: -24 }
    },
    getSizeType() {
      return { width: 54, height: 62 }
    },
    getImgType: function (dev) {
      //dev.running  亮灯 灭灯  2
      //dev.online  在线 离线 1
      //dev.alarm  报警 不报警  2
      let imgURl = this.img.gis.pole.out0
      let name = '',
        num
      num = dev.pcnt > 3 ? 4 : dev.pcnt
      if (dev.pcnt == 0) {
        name = 'out'
      } else if (!dev.online) {
        if (dev.isPowerOn) {
          name = 'outOff'
        } else {
          name = 'out'
        }
      } else {
        if (dev.alarm) {
          if (dev.running) {
            name = 'lightingP'
          } else {
            name = 'outP'
          }
        } else {
          if (dev.running) {
            name = 'lighting'
          } else {
            name = 'out'
          }
        }
      }
      imgURl = this.img.gis.pole[name + num]
      if (imgURl == undefined) {
        imgURl = this.img.gis.pole.out0
      }
      return imgURl
    },
    groupInfo() {
      this.$emit('overLayBut', this.sample)
    },
    draw({ el, BMap, map }) {
      // const { lng, lat } = this.position
      const pixel = map.pointToOverlayPixel(new BMap.Point(this.item.lng, this.item.lat))
      console.log('over lay draw', this.item, el, map, BMap, pixel)
      // this.offsetWidth = this.$refs[`overlayBox${this.sample.name}`].offsetWidth;
      if (this.offsetWidth == 0) {
        this.offsetWidth = 80
      }
      el.style.left = '100px' // pixel.x - this.offsetWidth / 2 + 'px'
      el.style.top = '100px' //pixel.y - 34 + 'px';
      this.keyIndex = Math.random() * 10000000000000
    }
  }
}
</script>

<style>
.overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
}
.overlay-box {
  display: flex;
  /* width: auto; */
  height: 34px;
  background: #3880fc;
  border-radius: 17px;
  line-height: 34px;
  padding-right: 10px;
  /* position: relative; */
  /* border: solid 1px red; */
}
.overlay-img {
  width: 28px;
  height: 28px;
  flex: none;
  background: #ffffff;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  margin-left: 3px;
  margin-top: 3px;
}
.overlay-img img {
  width: 14px;
  height: 18px;
}
.overlay-line {
  flex: none;
  width: 1px;
  height: 28px;
  background: #ffffff;
  display: inline-block;
}
.overlay-name {
  white-space: nowrap;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  /* margin-left: 9px; */
  vertical-align: top;
  border-right: 1px solid #fff;
  padding: 0 15px;
}
.overlay-num {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  vertical-align: top;
  margin: 0 9px;
}
.overlay-triangle {
  width: 0;
  height: 0;
  border-top: 8px solid #3880fc;
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;
  position: absolute;
  top: 34px;
  left: 60px;
  /* border: solid 1px red; */
}
</style>
