<template>
  <bm-control :offset="item.offset">
    <div class="map-tool-container">
      <div :class="['item', item.type == 'BMAP_NORMAL_MAP' ? 'active' : '']" @click="switchMapType('BMAP_NORMAL_MAP')">
        {{showLang('gis.show.type.map')}}</div>
      <div :class="['item', item.type == 'BMAP_SATELLITE_MAP' ? 'active' : '']" @click="switchMapType('BMAP_SATELLITE_MAP')">
        {{showLang('gis.show.type.moon')}}</div>
    </div>
  </bm-control>
</template>
<script>
import { mapGetters } from 'vuex'
import {
  BmControl,
} from '@/components/vue-baidu-map/components'
export default {
  components: {
    BmControl
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default() {
        return {
          type: 'BMAP_NORMAL_MAP',
          offset: {
            width: 20,
            height: 30
          }
        };
      },
    },
  },
  watch: {
    // position: {
    //   handler() {
    //     this.$refs[`customOverlay${this.sample.name}`].reload()  // 当位置发生变化时，重新渲染，内部会调用draw
    //   },
    //   deep: true
    // }
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes', 'showLang']),
  },
  mounted() {
  },
  methods: {
    switchMapType: function (type) {
      this.$emit('changed', {type});
    },
  }
}
</script>

<style>
.map-tool-container {
  display: flex;
  border-radius: 3px;
  background-color: white;
  height: 26px;
}
.map-tool-container .item {
  height: 26px;
  background-color: white;
  color: black;
  cursor: pointer;
  border: solid 1px #1b5fa8;
  text-align: center;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  padding: 0 5px;
}
.map-tool-container .active {
  background-color: #1b5fa8;
  color: white;
}
</style>