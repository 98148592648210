<template>
  <bm-polyline :path="item.path.list" :key="`${item.id}_${item.type}`" stroke-color="blue" :clicking="true" :stroke-opacity="0.5"
              :stroke-weight="5" :editing="editLineId == item.id" @click="showMakerInfo" @lineupdate="updatePolylinePath($event,item)"></bm-polyline>
    <!-- <bm-label :content="item.name" :offset="getOffsetType(item)" :labelStyle="{width: '150px', textAlign:'center', backgroundColor: 'transparent', color: 'red', border: 0, fontSize : '12px'}" /> -->
</template>

<script>
// import { BmOverlay } from 'vue-baidu-map'
import {
  BmPolyline,
  // BmLabel,
} from '@/components/vue-baidu-map/components'
export default {
  components: {
    BmPolyline,
    // BmLabel,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    editLineId: {
      type: Number,
      default: 0,
    },
    item: {
      type: Object,
      default() {
        return {
          lat: 0,
          lng: 0,
          id: 0,
          type: 0,
          alarm: false,
          online: false,
          running: false,
          code: '',
          name: '',
          path: {},
          status: 1,
          single: true,
          sid: 0,
        };
      },
    },
  },
  data() {
    return {
      // editLineId: 0,
      offsetWidth: 80,
      keyIndex: 1,
    }
  },
  watch: {
    // editLineId(){
    //   console.log('edit line id', this.editLineId)
    // },
    // item(){
    //   console.log('map group total data changed', this.item)
    // }
    // position: {
    //   handler() {
    //     this.$refs[`customOverlay${this.sample.name}`].reload()  // 当位置发生变化时，重新渲染，内部会调用draw
    //   },
    //   deep: true
    // }
  },
  mounted() {
    this.sample = this.item;
  },
  methods: {
    showMakerInfo: function (e) {
      this.$emit("click", this.item);
      e.domEvent.stopPropagation();
    },
    updatePolylinePath(e, item) {
      // console.log('poly line path update', e, item);
      this.$set(item.path, 'list', e.target.getPath());
    },
  }
}
</script>

<style>
.overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
}
.overlay-box {
  display: flex;
  /* width: auto; */
  height: 34px;
  background: #3880fc;
  border-radius: 17px;
  line-height: 34px;
  padding-right: 10px;
  /* position: relative; */
  /* border: solid 1px red; */
}
.overlay-img {
  width: 28px;
  height: 28px;
  flex: none;
  background: #ffffff;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  margin-left: 3px;
  margin-top: 3px;
}
.overlay-img img {
  width: 14px;
  height: 18px;
}
.overlay-line {
  flex: none;
  width: 1px;
  height: 28px;
  background: #ffffff;
  display: inline-block;
}
.overlay-name {
  white-space: nowrap;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  /* margin-left: 9px; */
  vertical-align: top;
  border-right: 1px solid #fff;
  padding: 0 15px;
}
.overlay-num {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  vertical-align: top;
  margin: 0 9px;
}
.overlay-triangle {
  width: 0;
  height: 0;
  border-top: 8px solid #3880fc;
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;
  position: absolute;
  top: 34px;
  left: 60px;
  /* border: solid 1px red; */
}
</style>